<template>
  <div class="dw-wrap">
    <Transition name="button">
      <button
        v-if="!open"
        type="button"
        class="dw-open-button"
        @click="openPopup"
      >
        <img
          class="dw-open-button-image"
          src='data:image/svg+xml;utf8,<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.29"><path d="M18.3 11.4a3.56 3.56 0 0 1 3.43-3.43 3.43 3.43 0 0 1 3.43 3.43M6.86 11.4a3.56 3.56 0 0 1 3.43-3.43 3.43 3.43 0 0 1 3.43 3.43M8 19.4c0 3.78 3.58 6.86 8 6.86 4.79 0 8-3.69 8-6.86"/></g></svg>'
        />
      </button>
    </Transition>
    <Transition name="popup">
      <div v-if="open" class="dw-popup">
        <div class="dw-content">
          <template v-if="!result">
            <div class="dw-header">
              Хотите помочь?
              <button type="button" class="dw-close-button" @click="closePopup">
                ✕
              </button>
            </div>
            <div class="dw-amounts-title">Выберите размер пожертвования</div>
            <div class="dw-amounts">
              <buttons
                v-for="amount in amounts"
                type="button"
                class="dw-amount"
                :class="{ current: currentAmount == amount }"
                :key="amount"
                @click="setAmount(amount)"
                >{{ formatMoney(amount) }} {{ currecySymbol }}</buttons
              >
            </div>
            <label class="dw-current-amount-wrap">
              Или впишите свою сумму:
              <input
                class="dw-current-amount"
                :class="{ error: currentAmountError }"
                type="text"
                v-model.number="currentAmount"
              />
              {{ currecySymbol }}
            </label>
            <div class="dw-form-row">
              <label class="dw-from-label" for="dw-name"
                >Обязательные данные для чека:</label
              >
              <input
                placeholder="Имя"
                id="dw-name"
                class="dw-form-field dw-name"
                :class="{ error: nameError }"
                type="text"
                v-model="name"
              />
            </div>
            <div class="dw-form-row">
              <label class="dw-from-label" for="dw-email"
                >На E-mail мы пришлем чек, а не спам:</label
              >
              <input
                placeholder="E–mail"
                id="dw-email"
                class="dw-form-field dw-email"
                :class="{ error: emailError }"
                type="text"
                v-model="email"
              />
            </div>

            <div class="dw-form-row">
              <label class="dw-from-label" for="dw-email"
                >Целевое назначение:</label
              >
              <input
                placeholder="Назначение"
                id="dw-destination"
                class="dw-form-field dw-destination"
                :class="{ error: destinationError }"
                type="text"
                v-model="destination"
              />
            </div>

            <div class="dw-monthly-wrap">
              <button
                class="dw-monthly"
                :class="{ checked: monthly }"
                @click="monthly = !monthly"
              >
                Хочу помогать ежемесячно
              </button>
            </div>
            <div class="dw-text">
              При подписке на ежемесячные платежи Вы становитесь членом KBTU
              Alumni Association.
            </div>
            <div class="dw-tariffs-wrap">
              <a class="dw-tariffs" :href="tariffsHref" target="_blank">Информация о тарифах</a>
            </div>
            <button type="button" class="dw-send-button" @click="send">
              Отправить
            </button>
            <div class="dw-terms">
              Нажимая кнопку отправить Вы принимаете
              <a :href="termsHref" target="_blank">Условия</a>.
            </div>
          </template>
          <template v-else-if="result == 'success'">
            <div class="dw-header">
              Спасибо!
              <button type="button" class="dw-close-button" @click="closePopup">
                ✕
              </button>
            </div>
            <div class="dw-result-message">Спасибо за Ваше пожертвование</div>
            <button
              type="button"
              class="dw-big-close-button"
              @click="closePopup"
            >
              Закрыть
            </button>
          </template>
          <template v-else-if="result == 'fail'">
            <div class="dw-header dw-fail">
              Платеж не прошел
              <button type="button" class="dw-close-button" @click="closePopup">
                ✕
              </button>
            </div>
            <div class="dw-result-message">
              Возможно, на Вашей карте заблокированы онлайн-платежи.
            </div>
            <button
              type="button"
              class="dw-big-close-button"
              @click="closePopup"
            >
              Закрыть
            </button>
          </template>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import validateEmail from "@/utils/validateEmail.js";
import config from "@/config.json";

const width = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
);

let dataset = {};
let self = null;

export default {
  name: "App",
  data() {
    return {
      open: width > 960,
      amounts: config.amounts,
      currencyCode: config.currecyCode,
      currecySymbol: config.currecySymbol,
      currentAmount: config.amounts[0],
      monthly: false,
      name: "",
      email: "",
      destination: "",

      currentAmountError: false,
      nameError: false,
      emailError: false,
      destinationError: false,

      tariffsHref: null,
      termsHref: null,

      result: null,
    };
  },
  methods: {
    setData(data) {
      dataset = data;
      setTimeout(function () {
        self.tariffsHref = data.tariffs || null;
        self.termsHref = data.terms || null;
      }, 200);
    },
    openPopup() {
      this.open = true;
    },
    closePopup() {
      this.open = false;
      this.result = null;
    },
    formatMoney(amount) {
      return amount.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    setAmount(amount) {
      this.currentAmount = amount;
    },
    send() {
      this.currentAmountError = false;
      this.nameError = false;
      this.emailError = false;
      this.destinationError = false;

      if (this.currentAmount < 100) {
        this.currentAmountError = true;
      }

      if (!this.name || this.name.length < 2) {
        this.nameError = true;
      }

      if (!this.email || this.email.length < 2 || !validateEmail(this.email)) {
        this.emailError = true;
      }

      if (!this.destination || this.destination.length < 1) {
        this.destinationError = true;
      }

      const amount = parseInt(this.currentAmount);
      const widget = new window.tiptop.Widget();

      let data = null;
      if (this.monthly) {
        data = {
          name: this.name,
          comment: this.description,
          CloudPayments: {
            recurrent: {
              interval: "Month",
              period: 1,
            },
          },
        };
      } else {
        data = {
          name: this.name,
          comment: this.description,
        };
      }

      const charge = {
        publicId: dataset.publicId,
        description: dataset.description,
        amount: amount,
        currency: config.currecyCode,
        accountId: this.email,
        email: this.email,
        //skin: dataset.skin,
        data: data,
      };

      widget.pay("charge", charge, {
        onSuccess: function (/*options*/) {
          this.result = "success";
        },
        onFail: function (/*reason, options*/) {
          this.result = "fail";
        },
      });
    },
  },
  mounted() {
    self = this;
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,700&display=swap");

label {
  font-weight: 400;
}

.button-enter-active,
.button-leave-active {
  transition: opacity 0.5s ease;
}

.button-enter-from,
.button-leave-to {
  opacity: 0;
}

.popup-enter-active,
.popup-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.popup-enter-from,
.popup-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.dw-wrap,
.dw-wrap * {
  font-family: "Roboto", sans-serif;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dw-wrap {
  color: $color-text;
  position: fixed;
  right: 30px;
  bottom: 30px;
  font-size: 16px;
  z-index: 9996;
}

.dw-open-button {
  padding: 0;
  border-radius: 10px;
  border: none;
  background: $color-primary;
  background: linear-gradient(
    0deg,
    $color-primary 0%,
    lighten($color-primary, 20%) 100%
  );
  width: 80px;
  height: 80px;
  right: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
  transition: transform 0.5s ease, filter 0.5s ease;

  &:hover {
    filter: brightness(1.2);
    transform: scale(1.2);
  }
}

.dw-open-button-image {
  width: 80%;
  height: 80%;
}

.dw-popup {
  max-height: calc(100vh - 60px);
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 300px;
  box-sizing: border-box;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  @media screen and (max-width: 500px) {
    width: calc(100vw - 60px);
  }

  @media screen and (max-width: 380px) {
    right: -30px;
    bottom: -30px;
    width: calc(100vw);
    height: calc(100vh);
    max-height: none;
  }
}

.dw-content {
  border-radius: 15px 15px 10px 10px;
  background: #fff;
  padding-bottom: 20px;
  @media screen and (max-width: 380px) {
    min-height: calc(100vh - 20px);
  }
}

.dw-header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 300;
  height: 55px;
  background: $color-primary;
  background: linear-gradient(
    0deg,
    $color-primary 0%,
    lighten($color-primary, 20%) 100%
  );
  color: #fff;
  border-radius: 10px 10px 140px 140px / 10px 10px 25px 25px;
  position: relative;
  text-align: center;
  padding-bottom: 5px;
  /*margin-bottom: 20px;*/

  &.dw-fail {
    background: $color-danger;
    background: linear-gradient(
      0deg,
      $color-danger 0%,
      lighten($color-danger, 20%) 100%
    );
  }
}

.dw-close-button {
  position: absolute;
  right: 30px;
  top: 40%;
  width: 30px;
  height: 30px;
  color: #fff;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 18px;
  margin: -15px -15px -15px -15px;
  padding: 0;
  transition: 0.3s;

  &:hover {
    background: $color-secondary;
  }
}

.dw-amounts-title {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: 15px 20px 5px 20px;
}

.dw-result-message {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: 45px 20px 50px 20px;
}

.dw-amounts {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.dw-amount {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  background: $color-secondary;
  background: linear-gradient(
    0deg,
    $color-secondary 0%,
    lighten($color-secondary, 20%) 100%
  );
  width: 70px;
  height: 70px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba($color-primary, 0);
  transition: filter 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    filter: brightness(1.2);
  }

  &.current {
    box-shadow: 0 0 0 10px rgba($color-secondary, 0.2);
  }
}

.dw-current-amount-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 20px 20px 20px;
}

.dw-current-amount {
  flex-grow: 1;
  flex-shrink: 1;
  width: 50px;
  height: 35px;
  text-align: center;
  border: none;
  background: $color-field;
  color: $color-text;
  transition: 0.3s ease;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 400;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;

  &:focus,
  &:active {
    outline: none;
    background: $color-field-focus;
  }

  &.error {
    box-shadow: inset 0px 0px 0px 1px red;
  }
}

.dw-monthly-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 5px 20px 20px 20px;
}

.dw-monthly {
  display: block;
  width: 100%;
  position: relative;
  border: none;
  background: none;
  padding: 0;
  text-align: left;
  color: $color-text;
  font-size: 14px;
  padding-left: 30px;

  &:hover {
    background: none;
    color: $color-text;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    //border: 1.1px solid $color-primary;
    box-shadow: 0 0 0 1px $color-primary;
    left: 0;
    top: 50%;
    margin-top: -10px;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    cursor: pointer;
    width: 10px;
    height: 5px;

    border: 2px solid $color-primary;
    border-top: none;
    border-right: none;
    transform: translate(-1px, -7px) rotate(-45deg);

    left: 3px;
    top: 50%;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:not(.checked):before {
    animation: bounce 0.7s ease 0s infinite forwards;
  }

  &:not(.checked):after {
    animation: bounce2 0.7s ease 0s infinite forwards;
  }

  @keyframes bounce {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.3);
    }
  }

  @keyframes bounce2 {
    0% {
      transform: translate(-1px, -7px) scale(0.8) rotate(-45deg);
    }
    100% {
      transform: translate(-1px, -7px) scale(1.3) rotate(-45deg);
    }
  }

  &.checked::after {
    opacity: 1;
  }
}

.dw-form-row {
  padding: 0 20px 15px 20px;
}

.dw-from-label {
  display: block;
  font-size: 14px;
  margin-block: 5px;
}

.dw-form-field {
  display: block;
  width: 100%;
  height: 35px;
  border: none;
  background: $color-field;
  color: $color-text;
  transition: 0.3s ease;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 13px;

  &:focus,
  &:active {
    outline: none;
    background: $color-field-focus;
  }

  &::placeholder {
    color: #757575;
    opacity: 1;
  }

  &:focus::placeholder {
    color: #666666;
    opacity: 1;
  }

  &.error {
    box-shadow: inset 0px 0px 0px 1px red;
  }
}

.dw-text {
  display: block;
  padding: 0 20px 10px 20px;
  font-size: 14px;
  text-align: left;
}

.dw-terms {
  display: block;
  padding: 20px 20px 0 20px;
  font-size: 14px;
  text-align: center;

  a {
    color: $color-primary;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: $color-secondary;
      text-decoration: underline;
    }
  }
}

.dw-tariffs-wrap {
  display: block;
  padding: 0 20px 20px 20px;
  font-size: 14px;
  text-align: left;
}

.dw-tariffs {
  color: $color-primary;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: $color-secondary;
    text-decoration: underline;
  }
}

.dw-send-button,
.dw-big-close-button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 20px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  background-color: #179bd7;
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
  width: calc(100% - 40px);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #666666;
  }
}
</style>
