import { createApp } from "vue";
import App from "./App.vue";

let script = document.currentScript;
if (!script) {
  const scripts = document.getElementsByTagName("script");
  script = scripts[scripts.length - 1];
}

console.log(script);

function init() {
  const dataset = JSON.parse(JSON.stringify(script.dataset));

  var cloudpayments = document.createElement("script");
  cloudpayments.setAttribute(
    "src",
    //"https://widget.cloudpayments.ru/bundles/cloudpayments.js"
    "https://widget.tiptoppay.kz/bundles/widget.js"
  );
  document.head.appendChild(cloudpayments);

  const element = document.createElement("div");
  document.body.appendChild(element);

  const app = createApp(App);
  app.mount(element);
  app._component.methods.setData(dataset);
}

if (
  document.readyState === "complete" ||
  document.readyState === "interactive"
) {
  init();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    init();
  });
}
